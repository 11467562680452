import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import { jwtDecode } from 'jwt-decode'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let userDataLocalStorage = localStorage.getItem('userData') ?? ''
userDataLocalStorage = userDataLocalStorage ? jwtDecode(userDataLocalStorage).userData : null
const userData = userDataLocalStorage ? userDataLocalStorage : undefined
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
