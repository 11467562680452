export default [
  {
    path: '/configuracaoConta', //PRECISA USAR ESSE DATA.ID
    name: 'ConfiguracoesPerfil',
    component: () => import('@/views/pages/conta-config/configuracaoConta.vue'),
    meta: {
      pageTitle: 'Alterar dados do perfil',
      requiresParametrizacao: true,
    },
  },
  {
    path: '/help/centralapoio', //PRECISA USAR ESSE DATA.ID
    name: 'centralapoio',
    component: () => import('@/views/pages/help/centralapoio.vue'),
    meta: {
      pageTitle: 'Central de apoio',
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/modaltomador',
    name: 'modalCadastroTomador',
    component: () => import('@/views/components/Tomador/modalCadastroTomador.vue'),
    meta: {
      pageTitle: 'Tomador',
    },
  },
  {
    path: '/cadastro/grupoFolha',
    name: 'CadastroGrupoFolha',
    component: () => import('@/views/pages/cadastro/grupoFolha.vue'),
    meta: {
      pageTitle: 'Grupo Folha',
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/tomador',
    name: 'CadastroTomador',
    component: () => import('@/views/pages/cadastro/tomador.vue'),
    meta: {
      pageTitle: 'Tomador',
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/CadastroUsuario',
    name: 'CadastroUsuario',
    component: () => import('@/views/pages/cadastro/usuario.vue'),
    meta: {
      pageTitle: 'Usuário',
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/orgao',
    name: 'CadastroOrgao',
    component: () => import('@/views/pages/cadastro/orgao.vue'),
    meta: {
      pageTitle: 'Órgão',
      requiresParametrizacao: true,
    },
  },
  {
    path: '/cadastro/instituto',
    name: 'CadastroUnidadeGestora',
    component: () => import('@/views/pages/cadastro/instituto.vue'),
    meta: {
      pageTitle: 'Unidade Gestora',
      meta: { requiresAuth: true },
      requiresParametrizacao: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/error/nao-autorizado.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/too-large',
    name: 'too-large',
    component: () => import('@/views/error/muito-grande.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
