// Versão 1
// export default function(input) {
//   // if (input.length > 5) return String(input).substring(0,6)
//   const currentValue = input.replace(/[^0-9]/g, ''); // Remove tudo que não é número
//   const newValue = (currentValue).slice(-4); // Mantém apenas os últimos 5 caracteres

//   // Aplica a máscara
//   const formattedValue = newValue.slice(0, 1) + ',' + newValue.slice(1);
//   return formattedValue
// };

// Versão 2
// export default {
//   bind(e) {
//     // Permite teclas de controle (backspace, setas, etc.)
//     if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) return;

//     // Bloqueia qualquer input que não seja número
//     if (!e.key.match(/[0-9]/)) {
//       e.preventDefault();
//       return;
//     }

//     const currentValue = input.value.replace(/[^0-9]/g, ''); // Remove tudo que não é número
//     const newValue = (currentValue + e.key).slice(-5); // Mantém apenas os últimos 5 caracteres

//     // Aplica a máscara
//     const formattedValue = newValue.slice(0, 1) + ',' + newValue.slice(1);
//     input.value = formattedValue;

//     // Previne a inserção do número pela lógica padrão do navegador
//     e.preventDefault();
//   }
// };

// Versão 3
export default {
  inserted(el) {
    const input = el.tagName === 'INPUT' ? el : el.querySelector('input')

    if (input) {
      input.addEventListener('keydown', (e) => {
        // Permite teclas de controle (backspace, setas, etc.)
        if (['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) return

        // Bloqueia qualquer input que não seja número
        if (!e.key.match(/[0-9]/)) {
          e.preventDefault()
          return
        }

        const currentValue = input.value.replace(/[^0-9]/g, '') // Remove tudo que não é número
        const newValue = (currentValue + e.key).slice(-5) // Mantém apenas os últimos 5 caracteres

        // Aplica a máscara
        const formattedValue = newValue.slice(0, 1) + ',' + newValue.slice(1)
        input.value = formattedValue

        // Previne a inserção do número pela lógica padrão do navegador
        e.preventDefault()
      })
    }
  },
}
