<template>
  <v-select v-bind="$attrs" v-on="$listeners">
    <template #no-options>
      <div class="my-no-options-message">Nenhum registro encontrado!</div>
    </template>
  </v-select>
</template>

<script>
  import vSelect from 'vue-select'
  export default {
    name: 'VSelectPT',
    components: {
      vSelect,
    },
    inheritAttrs: true,
  }
</script>
