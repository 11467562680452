import Vue from 'vue'
import Swal from 'sweetalert2'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import number from '@coders-tm/vue-number-format'
import VueMask from 'v-mask'
import router from './router'
import store from './store'
import App from './App.vue'
import './tailwind.css'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import { messages } from '@/libs/utils'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as directives from './@core/directives/diretivasGlobais'
import { createPinia, PiniaVuePlugin } from 'pinia'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/pt-br'
import { jwtDecode as decode } from 'jwt-decode'

Object.keys(directives).forEach((key) => {
  Vue.directive(key, directives[key])
})
Vue.use(DatePicker)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(number, {
  decimal: ',',
  separator: '.',
  //prefix: "R$ ",
  precision: 2,
  masked: false,
  reverseFill: true,
  prefill: false,
})
Vue.use(PiniaVuePlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueMask)
// import core styles
require('@core/scss/core.scss')
// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const pinia = createPinia()

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  pinia,
})

export const jwtDecode = app.$jwtDecode
export const message = messages(app.$toast)
export const sweetAlert = app.$swal

// Configurando jwtDecode como global
Vue.prototype.$jwtDecode = decode
Vue.prototype.$message = message

app.$mount('#app')
