import jwtDefaultConfig from './jwtDefaultConfig'
import router from '../../../router'
import axios from 'axios'
import { getUserData } from '../../../auth/utils'
import { boundary } from './boundary'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { jwtDecode } from 'jwt-decode'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    axiosIns.defaults.timeout = 240000

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        const acceptedMethods = ['post', 'patch', 'put', 'delete']
        if (acceptedMethods.includes(config.method)) {
          const userData = getUserData()
          if (userData) {
            config.headers = {
              ...config.headers,
              identidadeUsuario: userData.id,
              identidadeNome: userData.name,
              identidadeGrupoAcesso: userData.role,
              identidadeinstituto: userData.institutoSelecionado,
            }
          }
        }
        return config
      },
      (error) => Promise.reject(error),
    )

    // Verifica se o token expirou ou se houve erro na conexão
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const dataStorage = localStorage.getItem('userData')
        let userData = null
        if (dataStorage) {
          userData = jwtDecode(dataStorage)['userData']
        }

        const { status } = error.response
        if (status === 401 || (!userData && status === 401)) {
          setTimeout(() => {
            // Logout user
            const possuiUserData = !!getUserData()
            if (possuiUserData) {
              Vue.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Sua sessão expirou. Você será redirecionado para a página de login.',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            }

            // Remove tokens and user data from localStorage
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')

            // Redirect to login page after 5 seconds
            router.push('/login').catch(() => {})
          }, 5000)
        }
        return Promise.reject(error)
      },
    )
  }

  isNetworkError(err) {
    return !!err.isAxiosError && !err.response
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  recoverPassword(...args) {
    return this.axiosIns.patch(this.jwtConfig.recoverPasswordEndpoint, ...args)
  }

  GetloginTomador(...args) {
    return this.axiosIns.post(this.jwtConfig.loginTomadorEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  verifyPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.verifyEndpoint, ...args)
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotEndpoint, ...args)
  }

  async pesquisar(controller, ...args) {
    return await this.axiosIns.post(controller, ...args)
  }

  async post(controller, ...args) {
    return await this.axiosIns.post(controller, ...args)
  }

  async patch(controller, ...args) {
    return await this.axiosIns.patch(controller, ...args)
  }

  async postDocumento(controller, onUploadProgress, ...args) {
    return await this.axiosIns.post(controller, ...args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }

  async downloadManual(controller) {
    return await this.axiosIns.get(controller, {
      responseType: 'arraybuffer',
    })
  }

  async downloadDocumento(controller, id) {
    return await this.axiosIns.get(controller + '/' + id)
  }

  async delete(controller, id) {
    return await this.axiosIns.delete(controller + '/' + id)
  }

  async edit(controller, id) {
    return await this.axiosIns.get(controller + '/' + id)
  }

  async update(controller, id, ...args) {
    return await this.axiosIns.put(controller + '/' + id, ...args)
  }

  async put(controller, ...args) {
    return await this.axiosIns.put(controller, ...args)
  }

  async updateDocumento(controller, id, args) {
    return await this.axiosIns.put(controller + '/' + id, args)
  }

  async get(controller, options) {
    if (options) {
      return await this.axiosIns.get(controller, options)
    }
    return await this.axiosIns.get(controller)
  }

  async enviarSMS({ title, receiver, content }) {
    return this.axiosIns.post(
      this.jwtConfig.enviarSMSEndpoint,
      {
        Sender: title,
        Receivers: receiver,
        Content: content,
      },
      {
        headers: {
          'auth-key': process.env.SMS_AUTH_KEY,
        },
      },
    )
  }
}
