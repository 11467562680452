import useJwt from '@/auth/jwt/useJwt'
import { jwtDecode } from 'jwt-decode'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */

export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => {
  const userDataLocalStorage = localStorage.getItem('userData')
  if (userDataLocalStorage) {
    const decodedUserData = jwtDecode(userDataLocalStorage)['userData']
    return decodedUserData
  } else {
    return undefined
  }
}

export const getGrupoAcesso = () => {
  const userDataLocalStorage = localStorage.getItem('userData')
  if (userDataLocalStorage) {
    const decodedUserData = jwtDecode(userDataLocalStorage)['userData']
    if (!decodedUserData.GrupoAcesso) {
      return decodedUserData.GrupoAcesso
    } else return undefined
  } else {
    return undefined
  }
}

/**
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole) return '/'
  return { name: 'auth-login' }
}

export const hasPermissao = (perm, grupoAcesso) => {
  try {
    if (!grupoAcesso) {
      grupoAcesso = getGrupoAcesso()
    }

    if (!grupoAcesso) {
      return false
    }

    const hasPerm = grupoAcesso?.filter((item) => item.chave === perm).length > 0
    return hasPerm
  } catch (error) {
    console.error('[hasPermissao]', error)
    return false
  }
}
